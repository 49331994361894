import { useState } from "react";
import { slide as Menu } from "react-burger-menu";
import DonateBtn from "../DonateBtn/DonateBtn";
import "./BurgerMenu.css";

const links = [
  { title: "Letter from CEO", href: "/#letter-from-ceo" },
  { title: "Year in numbers", href: "/#year-in-numbers" },
  { title: "Why does SFL exist", href: "/#why-sfl-exist" },
  { title: "Feature Stories", href: "/#featured-stories" },
  { title: "Around the Globe", href: "/#global-presence" },
  {
    title: "Mentions, Quotes, and Commentary",
    href: "/#mentions-quotes-commentary",
  },
  { title: "Watch, Learn, Live", href: "/#watch-learn-live" },
  { title: "Testimonials", href: "/#testimonials" },
  { title: "Board Of Directors", href: "/#board-of-directors" },
  { title: "Invest in Liberty", href: "/#invest-in-liberty" },
  { title: "Finances", href: "/#finances" },
  { title: "SFL Website", href: "https://studentsforliberty.org/" },
];

const BurgerMenu = () => {
  const [menuOpen, setMenuOpen] = useState(false);

  const handleStateChange = (state) => {
    setMenuOpen(state.isOpen);
  };

  const closeMenu = () => {
    setMenuOpen(false);
  };
  return (
    <Menu
      left
      isOpen={menuOpen}
      onStateChange={handleStateChange}
      customBurgerIcon={<span className="icon-ellipsis-vertical-solid"></span>}
      customCrossIcon={<span className="icon-xmark-solid"></span>}
      className="burger-menu"
    >
      {links.map((link, index) => (
        <a
          key={index}
          className="menu-item"
          href={link.href}
          target={index === links.length - 1 ? "_blank" : "_self"}
          rel={index === links.length - 1 ? "noopener noreferrer" : undefined}
          onClick={closeMenu}
        >
          {link.title}
        </a>
      ))}

      <DonateBtn href="https://studentsforliberty.org/donate/" />
    </Menu>
  );
};

export default BurgerMenu;
