import { useEffect, useRef } from "react";
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import Splitting from "splitting";
import { animateTitle1 } from "../../helpers/animateTitle";

import VideoSlider from "../VideoSlider/VideoSlider";

import lImage from "../../assets/images/learn-liberty.webp";
import lLogo from "../../assets/images/ll-logo.webp";

import "./learn.css";

const LearnLiberty = () => {
  const titleRef = useRef();

  useEffect(() => {
    gsap.registerPlugin(ScrollTrigger);
    Splitting();

    animateTitle1(titleRef.current);
  }, []);

  return (
    <section className="commentary bg-grey" id="watch-learn-live">
      <div className="container">
        <div className="commentary-top-content">
          <div className="commentary-headings-wrapper">
            <h2>watch, learn, live</h2>
            <h3 ref={titleRef} data-splitting>
              Learn Liberty yields great results
            </h3>
          </div>
          <div className="commentary-img-wrapper">
            <img src={lLogo} alt="Learn Liberty" />
          </div>
        </div>
        <div className="commentary-content-wrapper">
          <div className="commentary-content-left">
            <p>
              Since coming under Students For Liberty’s stewardship five years
              ago, Learn Liberty has emerged as the engine for advancing our
              educational mission. During this period, our content and outreach
              have generated an estimated $4 million in brand exposure,
              amplifying our ability to reach and engage classical liberal
              learners worldwide. This fiscal year was our strongest yet, with
              Learn Liberty’s channels achieving a record-breaking 11 million
              views. By delivering accessible and compelling videos that
              illuminate the ideas of liberty and free societies, Learn Liberty
              remains indispensable in advancing SFL’s mission.
            </p>
            <div className="views-wrapper">
              <div className="commentary-views-img-wrapper">
                <span className="icon-Icons_Learn-Liberty icomoon"></span>
              </div>
              <div>
                <p>6.49M</p>
                <p>Views in FY 24</p>
              </div>
            </div>
          </div>
          <img src={lImage} className="learn-libert-img" alt="Learn Liberty" />
        </div>
        <h2>top 5 best performing video</h2>
      </div>

      <VideoSlider />
    </section>
  );
};

export default LearnLiberty;
