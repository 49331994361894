import stamp from "../../assets/images/2024-stamp.webp";
import "./hero.css";

const Hero = () => {
  return (
    <section className="hero">
      <div className="container">
        <img className="hero-img" src={stamp} alt="2024" />
        <div className="hero-content-wrapper border-radius-20">
          <h1 className="hero-heading">
            Human hearts
            <br /> want to be Free
          </h1>
          <h2>Freedom for One, Prosperity for All</h2>
        </div>
      </div>
    </section>
  );
};

export default Hero;
