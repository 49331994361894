import { useEffect, useRef } from "react";
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import Splitting from "splitting";
import { animateTitle1 } from "../../helpers/animateTitle";

import SliderQuotes from "../SliderQuotes/SliderQuotes";

import "./testimonials.css";

const Testimonials = () => {
  const titleRef = useRef();

  useEffect(() => {
    gsap.registerPlugin(ScrollTrigger);
    Splitting();

    animateTitle1(titleRef.current);
  }, []);
  return (
    <section className="testimonials bg-white" id="testimonials">
      <div className="container">
        <div className="testimonials-header-wrapper">
          <h2>TESTIMONIALS</h2>
          <h3 ref={titleRef} data-splitting>
            Students For Liberty is <br />
            Life-changing Experience
          </h3>
        </div>
      </div>

      <SliderQuotes />
    </section>
  );
};

export default Testimonials;
