import { useRef, useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import Splitting from "splitting";
import { animateTitle1 } from "../../helpers/animateTitle";
import stories from "../../resources/stories";

import "./global-presence.css";

const GlobalPresence = () => {
  const titleRef = useRef();

  useEffect(() => {
    gsap.registerPlugin(ScrollTrigger);
    Splitting();
    animateTitle1(titleRef.current);
  }, []);

  const [activeTab, setActiveTab] = useState("North America");

  const tabs = [
    "North America",
    "Asia Pacific",
    "Europe",
    "Africa",
    "Latin America",
    "MENA",
    "South Asia",
    "Brazil",
  ];

  const activeTabContent = stories[activeTab];

  return (
    <section className="global-presence bg-grey" id="global-presence">
      <div className="container">
        <h2>around the globe</h2>
        <h3 ref={titleRef} data-splitting>
          Transforming the World:
          <br /> Our Latest Stories by Regions
        </h3>
        <div className="globe-info">
          <div>
            <p>SFL is present in</p>
            <span>109</span>
            <p>countries around the globe</p>
          </div>

          <span className="icon-Icons-07 icomoon"></span>
        </div>
        <div className="tabs-wrapper">
          <ul className="tabs">
            {tabs.map((tab) => (
              <li
                key={tab}
                onClick={() => setActiveTab(tab)}
                className={activeTab === tab ? "active" : ""}
              >
                <span>{tab}</span>
              </li>
            ))}
            <li className="info">
              <p>SFL is present in</p>
              <span>109</span>
              <p>countries around the globe</p>
              <span className="icon-Icons-07 icomoon"></span>
            </li>
          </ul>

          <div className="tab-content">
            {activeTabContent.map((item) => (
              <div
                className={`tab-card border-radius-20 ${
                  activeTabContent.length < 3 ? "horizontal" : ""
                }`}
                key={item.name}
              >
                <div className="tab-card-img-wrapper">
                  <img src={item.image} alt="storie-thumbnail" />
                </div>
                <div className="tab-card-content-wrapper">
                  <span>{item.location}</span>
                  <h4>{item.name}</h4>
                  <p>{item.descr[0]}</p>
                  <Link to={`/single-storie/${activeTab}-${item.location}`}>
                    Read more ›
                  </Link>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </section>
  );
};

export default GlobalPresence;
