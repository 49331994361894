import { useEffect, useRef, useState } from "react";
import { useInView } from "react-intersection-observer";

import Line from "../Line/line";
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import Splitting from "splitting";
import { animateTitle1 } from "../../helpers/animateTitle";
import CountUp from "react-countup";
import "./year-in-numbers.css";

const YearInNumbers = () => {
  const [countVisible, setCountVisible] = useState(false);

  const titleRef = useRef();

  useEffect(() => {
    gsap.registerPlugin(ScrollTrigger);
    Splitting();
    animateTitle1(titleRef.current);
  }, []);

  const { ref: sectionRef, inView } = useInView({
    threshold: 0.1,
    triggerOnce: true,
  });

  useEffect(() => {
    if (inView) {
      setCountVisible(true);
    }
  }, [inView]);

  return (
    <section
      className="year-in-number bg-white"
      ref={sectionRef}
      id="year-in-numbers"
    >
      <div className="container">
        <h2>Year in numbers</h2>
        <h3 ref={titleRef} data-splitting style={{ width: "80%" }}>
          The largest international pro-liberty student organization in the
          world!
        </h3>

        <div className="content-wrapper">
          <div className="dashboard-wrapper">
            <div className="card large-card border-radius-20">
              <CountUp
                start={0}
                end={1544974}
                delay={0.5}
                duration={6}
                separator=","
                enableScrollSpy={true}
                scrollSpyOnce={true}
              >
                {({ countUpRef }) => (
                  <div style={{ position: "relative" }}>
                    <span className="number-one" ref={countUpRef} />
                    {countVisible ? <Line /> : null}
                  </div>
                )}
              </CountUp>

              <p>Cumulative event attendees since FY11</p>
              <span className="icon-Icons_1_Cummulative-attendees year-in-number__icon"></span>
            </div>
            <div className="card medium-card border-radius-20">
              <CountUp
                start={0}
                end={229660}
                delay={0.5}
                duration={5}
                separator=","
                enableScrollSpy={true}
                scrollSpyOnce={true}
              >
                {({ countUpRef }) => (
                  <div>
                    <span className="number-one" ref={countUpRef} />
                  </div>
                )}
              </CountUp>
              <p>Total reach in FY24</p>
              <span className="icon-Icons_2_Total-reach year-in-number__icon"></span>
            </div>
          </div>
          <div className="small-cards-wrapper">
            <div className="card small-card border-radius-20">
              <CountUp
                start={0}
                end={14}
                delay={0.5}
                duration={5}
                prefix="$"
                suffix="M"
                enableScrollSpy={true}
                scrollSpyOnce={true}
              >
                {({ countUpRef }) => (
                  <div>
                    <span className="number-one" ref={countUpRef} />
                  </div>
                )}
              </CountUp>
              <p>
                Economic
                <br /> Impact
              </p>
            </div>
            <div className="card small-card border-radius-20">
              <CountUp
                start={0}
                end={241}
                delay={0.5}
                duration={5}
                suffix="%"
                enableScrollSpy={true}
                scrollSpyOnce={true}
              >
                {({ countUpRef }) => (
                  <div>
                    <span className="number-one" ref={countUpRef} />
                  </div>
                )}
              </CountUp>
              <p>
                Return on investment:
                <br /> $1 = $2.41 of impact
              </p>
            </div>
            <div className="card small-card border-radius-20">
              <CountUp
                start={0}
                end={873}
                delay={0.5}
                duration={5}
                suffix="h"
                enableScrollSpy={true}
                scrollSpyOnce={true}
              >
                {({ countUpRef }) => (
                  <div>
                    <span className="number-one" ref={countUpRef} />
                  </div>
                )}
              </CountUp>
              <p>
                Hours training more <br /> than ever
              </p>
            </div>
          </div>
          <h3 style={{ textAlign: "center" }}>
            Every dollar of donor input produces $2.41 of impact.
          </h3>
          <div className="impact-grid">
            <div className="impact-item border-radius-20">
              <div className="impact-icon"></div>
              <span className="icon-Icons_4_People-trained year-in-number__impact-icon"></span>
              <span className="number-two">81,428</span>
              <p>Events participants</p>
            </div>
            <div className="impact-item border-radius-20">
              <div className="impact-icon"></div>
              <span className="icon-Icons_2_Total-reach year-in-number__impact-icon"></span>
              <span className="number-two">11,918</span>
              <p>People Trained</p>
            </div>
            <div className="impact-item border-radius-20">
              <div className="impact-icon"></div>
              <span className="icon-Icons_5_Students-Volunteers year-in-number__impact-icon"></span>
              <span className="number-two">2,500</span>
              <p>Students Volunteers</p>
            </div>
            <div className="impact-item border-radius-20">
              <div className="impact-icon"></div>
              <span className="icon-Icons-07 year-in-number__impact-icon"></span>
              <span className="number-two">3,807</span>
              <p>Events around the Globe</p>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default YearInNumbers;
