import "./btn.css";

const Btn = ({ text, href, className = "" }) => {
  return (
    <a
      href={href}
      className={`${className} btn`}
      target="_blank"
      rel="noopener noreferrer"
    >
      {text}
    </a>
  );
};

export default Btn;
