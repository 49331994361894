import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay } from "swiper/modules";
import "swiper/css/bundle";
import "./video-slider.css";

import playImage from "../../assets/icons/video-play-icon.png";

import image from "../../assets/images/video-slides/1.webp";
import image2 from "../../assets/images/video-slides/2.webp";
import image3 from "../../assets/images/video-slides/3.webp";
import image4 from "../../assets/images/video-slides/4.webp";
import image5 from "../../assets/images/video-slides/5.webp";

const videoSlides = [
  {
    thumb: image,
    link: "https://www.youtube.com/watch?v=LHrscNL8N9o",
    title: "Social Security Myths DEBUNKED: Prof. Davies Takes on Robert Reich",
    views: "21K",
  },
  {
    thumb: image2,
    link: "https://www.youtube.com/watch?v=Tc782vpjfvg",
    title: "How EVIL MORTY takes the Citadel of Ricks",
    views: "17K",
  },
  {
    thumb: image3,
    link: "https://www.youtube.com/watch?v=S0vG2GShZic",
    title: "Exposing 8 LIES About The USSR",
    views: "13K",
  },
  {
    thumb: image4,
    link: "https://www.youtube.com/watch?v=hVAbg5nAmaw",
    title: "8 Facts about the SECOND AMENDMENT",
    views: "402K",
  },
  {
    thumb: image5,
    link: "https://www.youtube.com/watch?v=I0L9XNNmdSQ",
    title: "Decoding The Ideology Of The Barbie Movie.",
    views: "2K",
  },
  {
    thumb: image3,
    link: "https://www.youtube.com/watch?v=S0vG2GShZic",
    title: "Exposing 8 LIES About The USSR",
    views: "13K",
  },
];

const VideoSlider = () => {
  return (
    <Swiper
      modules={[Autoplay]}
      spaceBetween={50}
      slidesPerView={4}
      loop={true}
      centeredSlides={true}
      autoplay={{ delay: 2000, pauseOnMouseEnter: true }}
      breakpoints={{
        1300: {
          slidesPerView: 4,
        },
        1100: {
          slidesPerView: 3.5,
          spaceBetween: 25,
        },
        768: {
          slidesPerView: 2.5,
          spaceBetween: 20,
        },
        320: {
          slidesPerView: 1.1,
          spaceBetween: 20,
        },
      }}
    >
      {videoSlides.map((video, i) => (
        <SwiperSlide key={i} className="video-slide">
          <div
            className="video-slide-content "
            style={{ backgroundImage: `url(${video.thumb})` }}
          >
            <a href={video.link} target="_blank">
              <img src={playImage} alt="play" />
            </a>
            <p className="video-views">
              <span className="icon-eye"></span>
              {video.views}
            </p>
          </div>
          <h4>{video.title}</h4>
        </SwiperSlide>
      ))}
    </Swiper>
  );
};

export default VideoSlider;
