import { useEffect, useRef } from "react";
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import Splitting from "splitting";
import { animateTitle1 } from "../../helpers/animateTitle";

import Btn from "../Btn/Btn";
import sflLogo from "../../assets/images/sfl-logo-black.webp";
import investImg from "../../assets/images/invest-in-liberty.webp";
import "./invest-in-liberty.css";

const InvestInLiberty = () => {
  const titleRef = useRef();

  useEffect(() => {
    gsap.registerPlugin(ScrollTrigger);
    Splitting();
    animateTitle1(titleRef.current);
  }, []);
  return (
    <section
      className="invest-in-liberty bg-yellow border-radius-35"
      id="invest-in-liberty"
    >
      <div className="container">
        <h2>Invest in Liberty</h2>
        <h3 ref={titleRef} data-splitting>
          Support SFL and Starve the Leviathan
        </h3>
        <div className="invest-in-liberty-content-wrapper">
          <p>
            <b>
              “A fine is a tax for doing something wrong. A tax is a fine for
              doing something right.”
            </b>
            Anonymous
          </p>
          <p>
            Think the government is the best place to spend your hard-earned
            dollars? Neither do we. But here’s the real surprise:
            <b> NEITHER DOES THE GOVERNMENT! </b>
          </p>
          <p>
            Contributions to Students For Liberty (SFL) are tax-deductible to
            the full extent of the law. What’s more, if you are of the “certain
            age” obligated to take Required Minimum Distributions (RMD) from
            your IRA, you can avoid taxation on your withdrawals while
            furthering your ideals by rolling over up to $105,000 to SFL. None
            of this money would be included in your gross income for the year.
            This means you can donate your RMD to SFL without paying any taxes
            on that contribution. It couldn’t be clearer! The government knows
            that supporting SFL will yield a better, freer future than if you
            pay your taxes. Who are we to argue? This is one case where we agree
            with our big brother in DC.
          </p>
          <p>
            Think this can’t get any better? We’ve shown that taxes are not
            inevitable. We can also help you cheat death. You can continue to
            enable us to educate, develop, and empower future leaders of liberty
            by including SFL in your estate plan. As you think about your
            philanthropic and legacy goals, please feel free to reach out
            <b>
              {" "}
              <a href="mailto:PlannedGiving@StudentsForLiberty.org">
                (PlannedGiving@StudentsForLiberty.org)
              </a>
            </b>{" "}
            to discuss how SFL can play a role in maximizing your impact on the
            future while minimizing the government’s bite out of resources
            you’ve worked a lifetime to build.
          </p>
          <p>
            None of what our students accomplished this year and none of what
            they are poised to do in the future would be possible without the
            generous ongoing support of you and people like you who share your
            values and your sense of optimism about the impact that
            well-motivated, well-educated, and well-skilled students can have on
            bringing about a freer future.
          </p>
          <p>Thank you.</p>
          <img src={sflLogo} alt="sfl" className="invest-sfl-logo" />
          <div>
            <Btn text={"Donate to SFL now!"} />
          </div>
          <img
            src={investImg}
            alt="invest in libert"
            className="invest-in-liberty-img"
          />
        </div>
      </div>
    </section>
  );
};

export default InvestInLiberty;
