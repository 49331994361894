import DonateBtn from "../DonateBtn/DonateBtn";
import Btn from "../Btn/Btn";
import logo from "../../assets/images/sfl-logo.webp";
import "./header.css";

const Header = () => {
  return (
    <header className="header">
      <div className="header-image-wrapper">
        <img src={logo} alt="SFL Logo" />
      </div>
      <div className="container">
        <div className="header-wrapper">
          <Btn text="SFLiberty.org" href="https://studentsforliberty.org/" />
          <DonateBtn href="https://studentsforliberty.org/donate/" />
        </div>
      </div>
    </header>
  );
};

export default Header;
