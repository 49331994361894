import { useEffect, useRef } from "react";
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import Splitting from "splitting";
import { animateTitle1 } from "../../helpers/animateTitle";
import featuredStoriesData from "../../resources/featuredStories";

import "./featured-stories.css";
import FeaturedStoryItem from "../FeaturedStoryItem/FeaturedStoryItem";

const FeaturedStories = () => {
  const titleRef = useRef();

  useEffect(() => {
    gsap.registerPlugin(ScrollTrigger);
    Splitting();
    animateTitle1(titleRef.current);
  }, []);
  const storiesRef = useRef(featuredStoriesData);

  return (
    <section className="featured-stories bg-white" id="featured-stories">
      <div className="container">
        <h2>Feature Stories</h2>
        <h3 ref={titleRef} data-splitting>
          Spotlight on Change:
          <br /> Explore Our Global Achievements!
        </h3>
        <p>
          Around the world, Students For Liberty empowers courageous leaders who
          risk their safety to promote liberty, innovate in journalism, and lead
          societal change. Through impactful events, bold publications, and
          influential alumni in top roles, SFL is shaping a global movement
          dedicated to freedom and individual rights.
        </p>
        {storiesRef.current.map((story, i) => (
          <FeaturedStoryItem key={i} {...story} />
        ))}
      </div>
    </section>
  );
};

export default FeaturedStories;
